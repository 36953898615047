import React, { useState } from 'react';
import { ApiCall } from '../../Utils/ApiService';
import { Alert, Button, Container } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ParseError} from "../../Utils/ParseError";


function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');

  const [error, setError] = useState(null);

  function validateForm() {
    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!username.value.match(validRegex)) {
      setError('Invalid email address.');
      return false;
    }
    //TODO: add more validations here.

    return true;
  }

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    if(validateForm() === false){
      setLoading(false);
      return;
    }
    
    //TODO: imlement reset password API 
    ApiCall('post', '/v1/auth/register', {
          email: username.value,
      }).then(response => {
        setLoading(false);
        console.log(response.data);  
    }).catch(error  => {
      console.log(error.response);
      setLoading(false);
      setError(ParseError(error));
    });
  }

  
  return (
    <>
      <br />
      <br />
      <Container>
        <div className='login_container_box'>
          <Row>
            <Col>
              <h1 className='text-center'>Register</h1>
            </Col>
          </Row>
          
          {error && <><Alert  variant="danger">{error}</Alert></>}
          
          <Row className="align-items-center mt-10">       
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address <span className='required'>*</span></Form.Label>
                  <Form.Control type="email" placeholder="Enter email"  {...username} autoComplete="off" tabIndex={1}/>
                  <Form.Text muted>
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>
              
                <div className='text-center'>
                  <Button variant="primary" onClick={handleLogin} disabled={loading} tabIndex={7}>{loading ? 'Loading...' : 'Send Reset Password Code'}</Button>
                </div>
              </Col>
          </Row>

          <Row>
              <Col>
                <hr />
                <div className='text-right'>
                Already have an account? <Link to="/login" tabIndex={8}>Login</Link>
                </div>
              </Col>
          </Row>
        </div>
      </Container>
      
    </>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default ForgotPassword;