import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard';
import PaymentCompleted from './pages/Payments/PaymentCompleted';
import PaymentFailed from './pages/Payments/PaymentFailed';
import Pay from './pages/Payments/Pay';
import ListOrders from './pages/Orders/ListOrders';

import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import { getToken, removeUserSession, setUserSession } from './Utils/Common';
import { ApiCall } from './Utils/ApiService';


import './App.scss';
import Register from './pages/Auth/Register';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Home from './pages/Home';
import CreateApiKey from "./pages/CreateApiKey";
import LoadingPage from "./components/LoadingPage";

function App() {
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
  

    ApiCall('get', '/v1/users',{},{"token":token}).then(response => {
          setUserSession(token, response.data.data.user);
          setAuthLoading(false);

        }).catch(error => {
          removeUserSession();
          setAuthLoading(false);
        });
      }, []);
  if (authLoading && getToken()) {
      return <LoadingPage text={"Checking Authentication..."}></LoadingPage>
  }


  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/register" component={Register} />
          <PublicRoute path="/forgot_password" component={ForgotPassword} />

           {/**Common routes.No need to be authenticated */}
          <Route path="/payment/success" component={PaymentCompleted} />
          <Route path="/payment/failed" component={PaymentFailed} />

          <PrivateRoute path="/pay" component={Pay} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/orders" component={ListOrders} />
          <PrivateRoute path="/api-key" component={CreateApiKey} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
