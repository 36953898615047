// remove the token and user from the session storage
import axios from 'axios';

/**
 * 
 * @param {String} method 
 * @param {String} endpoint 
 * @param {} params 
 * @param {} headers 
 * @returns Promise 
 */
export const ApiCall = (method, endpoint,params = {}, headers = {}) => {

    const API_URL = process.env.NODE_ENV === 'production'
        ? 'https://api.simplejwtlogin.com'
        : 'http://localhost:8888';

    return axios({
        method: method,
        url: API_URL + endpoint,
        params: params,
        headers: headers
    });
}
  