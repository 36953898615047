import React, { useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaPaypal } from 'react-icons/fa';
import { ApiCall } from '../../Utils/ApiService';


function Pay(props) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [approveLink, setAppoveLink] = useState(null);

    const amount = useFormInput('');

    const handleLogin = () => {
        setError(null);
        setLoading(true);

        ApiCall('GET', '/v1/paypal/pay', { currency:"EUR", amount: amount.value }).then(response => {
            setLoading(false);
            setAppoveLink(response.data.data.approve_link);
            //window.open(response.data.data.approve_link,'_blank');
          }).catch(error  => {
            setLoading(false);
            console.log(error.response);
            if (error && error.response && error.response.status === 422 && typeof error.response.data.errors !== 'undefined'){
              
              //  let message = JSON.stringify(error.response.data.errors.array.forEach(element => {
                
              //  });
               setError("Validation error."); //setError(error.response.data.message);
            }
            else 
               setError("Something is wrong. Please try again.");
          });

    }


    if (approveLink) {
        return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h1><FaPaypal></FaPaypal> Approve the transaction</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='text-center'>
                            <a className='btn btn-primary btn-small' href={approveLink} tabIndex={3}>{loading ? 'Loading...' : 'Approve ' + amount.value}</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
        );
    }

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h1><FaPaypal></FaPaypal> Create an order</h1>
                    </Col>
                </Row>

                {error && <><Alert  variant="danger">{error}</Alert></>}

                <Row>
                    <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>&euro; Amount</Form.Label>
                        <Form.Control type="text" placeholder="Please enter the amount you want to buy"  {...amount} autoComplete="off" tabIndex={1}/>
                        <Form.Text muted>
                            We will create a paypal order with thi amount.
                        </Form.Text>
                    </Form.Group>
                    <div className='text-center'>
                    <Button variant="primary" onClick={handleLogin} disabled={loading} tabIndex={3}>{loading ? 'Loading...' : 'Create Order'}</Button>
                    
                    </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}


const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
  
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
  }

export default Pay;