import React from 'react';
import { Alert, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function PaymentFailed(props) {

    return (
        <>
        <Container>
            <br />
            <br />
            <Row>
                <Col>
                    <Alert variant='danger'>Payment failed. You can see more details in your account.</Alert>
                </Col>
            </Row>

            <Row>
                <Col className='text-center'>

                <Link to='/dashboard' className='btn btn-primary'>Go to dashboard</Link>
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default PaymentFailed;