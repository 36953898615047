import React from 'react';
import { Alert, Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function PaymentCompleted(props) {

    return (
        <>
            <Container>
                <br />
                <br />
                <Row>
                    <Col>
                        <Alert variant='success'>Payment successfully. You can see the details in your account.</Alert>
                    </Col>
                </Row>

                <Row>
                    <Col className='text-center'>

                    <Link to='/dashboard' className='btn btn-primary'>Go to dashboard</Link>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PaymentCompleted;