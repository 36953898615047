import React  from 'react';
import {useEffect, useState } from 'react';

import { getToken } from '../../Utils/Common';
import { ApiCall } from '../../Utils/ApiService';

import {FaMoneyBillAlt} from 'react-icons/fa';
import { Button, Card, CardGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingPage from "../LoadingPage";

const Balances = () => {
  const [balances, setBalances] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

      ApiCall('get','/v1/users',{},{"token": getToken()})
      .then((res) => {
        setIsLoading(false);
        setIsError(false);
        setBalances(res.data.data.balances ?? []);
        setIsError(false);
      }).catch((err) => {
        setIsError(true);
        setIsLoading(false);
      })
  }, []);

  if (isLoading) {
    return <LoadingPage text={"Loading balances..."}></LoadingPage>;
  }

  if (isError) {
    return <p className='error'>There was an error trying to load the data...</p>;
  }

  return (
      <>
          <CardGroup>
              { balances.map( (oneBalance,index) => (
                  <Card className={"m-1 bg-accent" + (index+1)}>
                      <Card.Header as="h5">  <FaMoneyBillAlt></FaMoneyBillAlt> {oneBalance.fiat_symbol}</Card.Header>
                      <Card.Body className='size-4'>
                          index: {index}<br />
                          {oneBalance.balance}
                          <br />
                          <br />
                          <div className=''>
                              <Link className='btn btn-primary btn-sm' to={"/pay?currency=" + oneBalance.fiat_symbol}>Buy</Link>
                          </div>
                      </Card.Body>
                  </Card>
              ))
              }
          </CardGroup>
      </>
  );
  return (
    <div>
      <CardGroup>


        <Card className='bg-accent2 m-1'>
          <Card.Header as="h4">
              <FaMoneyBillAlt></FaMoneyBillAlt> USD
          </Card.Header>
          <Card.Body>
              {balances.USD} $
              <br />
              <div className='text-right'>
                <Button variant='primary' className='btn-sm' disabled>Buy</Button>
              </div>
          </Card.Body>
        </Card>
      </CardGroup>
      <CardGroup>
        <Card className='bg-accent3 m-1'>
          <Card.Header as="h4"> <FaMoneyBillAlt></FaMoneyBillAlt> GBP</Card.Header>
          <Card.Body>
              {balances.GBP} &#163;
              <br />
              <div className='text-right'>
                <Button variant='primary' className='btn-sm' disabled>Buy</Button>
              </div>
          </Card.Body>
        </Card>
        <Card className='bg-accent4 m-1'>
          <Card.Header as="h4"> <FaMoneyBillAlt></FaMoneyBillAlt> RON</Card.Header>
          <Card.Body>
              {balances.RON} LEI
              <br />
              <div className='text-right'>
                <Button variant='primary' className='btn-sm' disabled>Buy</Button>
              </div>
          </Card.Body>
        </Card>
      </CardGroup>  
    </div>
  );
};

export default Balances;