import React from 'react';
import {Spinner} from "react-bootstrap";

const LoadingPage = (props) => {
    let text = 'Loading';
    if(props.text) {
        text = props.text
    }

    return (
        <>
            <div className="text-center m-5" >
                <Spinner animation="border" role="status">
                </Spinner>
                <br/>
                <span className="text-muted">{text}...</span>
            </div>
        </>
    )
}

export default LoadingPage;