import React from 'react';
import { Badge, Button, Card, CardGroup, Container } from 'react-bootstrap';
import ApiKeys from '../components/apikeys/ApiKeys';
import Balances from '../components/balances/Balances';
import { getUser } from '../Utils/Common';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function Dashboard(props) {
  const user = getUser();

  return (
    <Container>
      {/* TODO: i need to split this information*/ }
      <CardGroup>
      <Card>
        <Card.Header className='' as="h3">User Details</Card.Header>
        <Card.Body>
          ENV: {process.env.NODE_ENV} <br />
          Welcome {user.last_name} {}!<br /><br />
          
          <b>First Name</b>: {user.first_name} <br />
          <b>Last Name</b> : {user.last_name} <br />
          <b>Company</b> : {user.company} <br />
          <b>Active</b> : {user.active ? 'true' : 'false'} <br />
          <b>Plan</b> : <Badge>{user.plan_id}</Badge>
        </Card.Body>
      </Card>
      </CardGroup>


      <Row className={"mt-2"}>
          <Col>
              <h2>Overview</h2>
          </Col>
      </Row>
        <Row>
          <Col>
              <Balances></Balances>
          </Col>
      </Row>


      <Card className={"mt-2"}>
        <Card.Header className='' as="h3">
          API Keys
        </Card.Header>
          <Card.Body>
              <div className='text-right'>
                <Button  href={"/api-key"} variant='primary' className='btn-sm' disabled={user.active !== true}>CREATE</Button>
              </div>
            <ApiKeys></ApiKeys>
          </Card.Body>
      </Card>  
    </Container>
  );
}

export default Dashboard;
