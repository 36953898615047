import React  from 'react';
import {useEffect, useState } from 'react';

import { getToken } from '../../Utils/Common';
import { ApiCall } from '../../Utils/ApiService';
import { Badge, Card } from 'react-bootstrap';
import LoadingPage from "../LoadingPage";

const ApiKeys = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
      ApiCall('get','/v1/users/api_key',{},{"token": getToken()})
      .then((res) => {
        setIsLoading(false);
        setIsError(false);
        setApiKeys(res.data.data.api_keys);
        setIsError(false);
      }).catch((err) => {
        setIsError(true);
        setIsLoading(false);
      })
  }, []);

  if (isLoading) {
    return <LoadingPage text={"Loading API Keys"}></LoadingPage>;
  }

  if (isError) {
    return <p className='error'>There was an error trying to load the data...</p>;
  }

  console.log(apiKeys);
  return (
    <div>
      {apiKeys.map((oneKey, index) => (
        <>
          <Card className='m-1'>
            <Card.Header>
            <Badge>{index+1}</Badge> <b>{oneKey.key} <Badge>{oneKey.brand}</Badge></b>
            </Card.Header>
            <Card.Body>
             <b>Currency</b>: {oneKey.currency}<br />
             <b>Code Expiration time</b>: {oneKey.code_expiration_time}s<br />
             <b>Provider</b>: {oneKey.provider}<br />
             <b>Created at</b>: {oneKey.created_at}<br />
             <b>Permissions</b>: {oneKey.permissions}<br />
             <b>Website</b>: {oneKey.website}<br />
              <div className={"blackbackground"}>
                <code >{JSON.stringify(oneKey)}</code>
              </div>

            </Card.Body>
          </Card>
          
        </>
       
      ))}
    </div>
  );
};

export default ApiKeys;