import React from 'react';
import {useEffect, useState} from 'react';

import {getToken} from '../../Utils/Common';
import {ApiCall} from '../../Utils/ApiService';
import {Alert, Container, Pagination, Table} from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoadingPage from "../../components/LoadingPage";
import {ParseError} from "../../Utils/ParseError";

const ListOrders = () => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState(false);

    //Pagination
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasNext, setHasNext] = useState(false);
    const [pagination, setPagination] = useState([]);

    let items = [];
    useEffect(() => {
        setIsLoading(true);
        ApiCall('get', '/v1/users/orders', {
            page: currentPage,
            page_size: 10
        }, {"token": getToken()})
            .then((res) => {
                setError(false);
                setIsLoading(false);

                setOrders(res.data.data);
                setTotalPages(res.data.pagination.total_pages);
                setCurrentPage(res.data.pagination.page);
                console.log('total pages', res.data.pagination.page)
                for (let number = 1; number <= res.data.pagination.total_pages; number++) {
                    //TODO: limit somehow the number of buttons that we are displaying
                    //TODO: call onclick on pagination item
                    console.log('pushing..');
                    items.push(
                        <Pagination.Item key={number} active={number === res.data.pagination.page}>
                            {number}
                        </Pagination.Item>,
                    );
                }
                setPagination(items);
            }).catch((err) => {
            setIsLoading(false);
            console.log(err);
            setError(ParseError(err));
        })
    }, []);

    if (isLoading) {
        return <LoadingPage text={"Loading orders"}></LoadingPage>;
    }

    if (error) {
        return (
            <Container>
                <Alert variant={"danger"}>{error}</Alert>
            </Container>
        );
    }

    console.log(orders);
    return (
        <Container>
            <h1>Orders {totalPages}</h1>
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Order Id</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                {orders.map((oneOrder, index) => (
                    <tr>
                        <td>{index + 1}</td>
                        <td>{oneOrder.order_id}</td>
                        <td>{oneOrder.amount} {oneOrder.currency}</td>
                        <td>{oneOrder.status}</td>
                        <td>{oneOrder.created_at}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Row>
                <Col>
                    <Pagination>{pagination}</Pagination>
                </Col>
            </Row>

        </Container>
    );
};

export default ListOrders;