import React, { useState } from 'react';
import { setUserSession } from '../../Utils/Common';
import { ApiCall } from '../../Utils/ApiService';
import { Alert, Button, Container } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ParseError} from "../../Utils/ParseError";


function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);


  function validateForm() {
    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!username.value.match(validRegex)) {
      setError('Invalid email address.');
      return false;
    }

    if(password.value.length <6){
       setError('Passwor lenght must be at least 6 characters.');
       return false; 
    }

    return true;
  }

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    if(validateForm() === false){
      setLoading(false);
      return;
    }
    
    ApiCall('post', '/v1/auth/login', { email: username.value, password: password.value }).then(response => {
      setLoading(false);
      console.log(response.data);
      setUserSession(response.data.data.token, response.data.data.user);
      props.history.push('/dashboard');
    }).catch(error  => {
      setLoading(false);
      setError(ParseError(error));
    });
  }

  return (
    <>
      <br />
      <br />
      <Container>
        <div className='login_container_box'>
          <Row>
            <Col>
              <h1 className='text-center'>Login</h1>
            </Col>
          </Row>
          
          {error && <><Alert  variant="danger">{error}</Alert></>}
          
          <Row className="align-items-center mt-10">       
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email"  {...username} autoComplete="off" tabIndex={1}/>
                  <Form.Text muted>
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" {...password} autoComplete="off" tabIndex={2}/>
                  <Form.Text muted>
                    Your password must be 6-20 characters long.
                  </Form.Text>
                  <br />
                  <div className='text-right'>
                    <Link to='/forgot_password' tabIndex={4}>Forgot password</Link>
                  </div>
                </Form.Group>
              
                <div className='text-center'>
                  <Button variant="primary" onClick={handleLogin} disabled={loading} tabIndex={3}>{loading ? 'Loading...' : 'Login'}</Button>
                </div>
              </Col>
          </Row>

          <Row>
              <Col>
                <hr />
                <div className='text-right'>
                Not a member? <Link to="/register" tabIndex={5}>Register now</Link>
                </div>
              </Col>
          </Row>
        </div>
      </Container>
      
    </>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;