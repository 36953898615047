import React from 'react';

import { getToken } from '../Utils/Common';

function Home(props) {
  
    console.log(getToken());
    if(getToken() !== null){
        props.history.push('/bashboard');   
    }

    props.history.push('/login');

    return (
        <>Logged out...</>
  );
}

export default Home;