import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Navigation from '../Navigation';
import { getToken } from './Common';

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => getToken() 
        ? <>
            <Navigation></Navigation>
            <div className="content">
              <Component {...props} />
            </div>
           
        </>
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }}
        
        />
      }
    />
  )
}

export default PrivateRoute;