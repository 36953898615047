import React, {useState} from 'react';
import {ApiCall} from '../../Utils/ApiService';
import {Alert, Button, Container} from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import {Link} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ParseError} from "../../Utils/ParseError";


function Register(props) {
    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');
    const phoneNumber = useFormInput('');
    const firstName = useFormInput('');
    const lastName = useFormInput('');
    const company = useFormInput('');


    const [error, setError] = useState(null);
    const [accountCreated, setAccoutCreted] = useState(false);


    function validateForm() {
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!username.value.match(validRegex)) {
            setError('Invalid email address.');
            return false;
        }

        if (password.value.length < 6) {
            setError('Password length must be at least 6 characters.');
            return false;
        }

        //TODO: add more validations here.

        return true;
    }

    // handle button click of login form
    const handleLogin = () => {
        setError(null);
        setLoading(true);
        if (validateForm() === false) {
            setLoading(false);
            return;
        }

        ApiCall('post', '/v1/auth/register', {
            email: username.value,
            password: password.value,
            company: company.value,
            phone_number: phoneNumber.value,
            first_name: firstName.value,
            last_name: lastName.value,
        }).then(response => {
            setLoading(false);
            console.log(response.data);
            setAccoutCreted(true);

        }).catch(error => {
            setLoading(false);
            console.log(error.response);
            setError(ParseError(error));
        });
    }

    if (accountCreated) {
        return (
            <>
                <br/>
                <br/>
                <Container>
                    <div className=''>
                        <Row>
                            <Col className='text-center m-6'>
                                <h1>Your account has been created.</h1>
                                <p className='text-muted'>You need to verify your email address in order to activate
                                    your account.</p>
                                <Link className='btn btn-primary' to={"/login"}>Login</Link>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </>
        );
    }

    return (
        <>
            <br/>
            <br/>
            <Container>
                <div className='login_container_box'>
                    <Row>
                        <Col>
                            <h1 className='text-center'>Register</h1>
                        </Col>
                    </Row>

                    {error && <><Alert variant="danger">{error}</Alert></>}

                    <Row className="align-items-center mt-10">
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address <span className='required'>*</span></Form.Label>
                                <Form.Control type="email" placeholder="Enter email"  {...username} autoComplete="off"
                                              tabIndex={1}/>
                                <Form.Text muted>
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="First Name"  {...firstName} autoComplete="off"
                                              tabIndex={2}/>
                                <Form.Text muted>
                                    Minimum 3 characters
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasiLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Last Name"  {...lastName} autoComplete="off"
                                              tabIndex={3}/>
                                <Form.Text muted>
                                    Minimum 3 characters
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" placeholder="Phone Number"  {...phoneNumber}
                                              autoComplete="off" tabIndex={4}/>
                                <Form.Text muted>
                                    We will never share your phone number with anyone else. Minimum 3 characters
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCompamy">
                                <Form.Label>Company</Form.Label>
                                <Form.Control type="text" placeholder="Company"  {...company} autoComplete="off"
                                              tabIndex={5}/>
                                <Form.Text muted>
                                    Minimum 3 characters
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" {...password} autoComplete="off"
                                              tabIndex={6}/>
                                <Form.Text muted>
                                    Your password must be 6-20 characters long.
                                </Form.Text>
                                <br/>
                            </Form.Group>


                            <input type="checkbox" id="terms" required="required"></input>
                            <label for="terms">&nbsp; I agree with the terms&conditions.</label>

                            <br/>
                            <br/>

                            <div className='text-center'>
                                <Button variant="primary" onClick={handleLogin} disabled={loading}
                                        tabIndex={7}>{loading ? 'Loading...' : 'Create Account'}</Button>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <hr/>
                            <div className='text-right'>
                                Already have an account? <Link to="/login" tabIndex={8}>Login</Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

        </>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default Register;