import React  from 'react';
import {useEffect, useState } from 'react';

import {Alert, Button, Container} from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {ApiCall} from "../Utils/ApiService";
import {getToken} from "../Utils/Common";
import {ParseError} from "../Utils/ParseError";

const CreateApiKey = (props) => {
    const [loading, setLoading] = useState(false);
    const [value,setValue]=useState('');
    const [error, setError] = useState(null);

    const codeExpirationTime = useFormInput('');
    const brandName = useFormInput('');
    const website = useFormInput('');
    useEffect(() => {
    }, []);

    const handleCreateApiKey = () => {
        setError(null);
        setLoading(true);

        ApiCall('post',
            '/v1/users/api_key',
            {
                currency: "EUR",
                provider_id: value,
                code_expiration_time: codeExpirationTime.value,
                brand: brandName.value,
                website: website.value
            },
            {"token": getToken()}
        ).then(response => {
            setLoading(false);
            console.log(response.data);
            props.history.push('/dashboard');
        }).catch(error  => {
            setLoading(false);
            console.log(error.response);
            setError(ParseError(error));
        });
    }


    return (
        <Container>

            {error && <Row>
                <Col>
                    <Alert  variant="danger">{error}</Alert>
                </Col>
            </Row>}

            <Row className="align-items-center mt-10">
                <Col>
                    <Form.Group className="mb-3" controlId="formBasicCodeExpirationTime">
                        <Form.Label>OTP Code expiration time</Form.Label>
                        <Form.Control type="number" placeholder="Number of seconds the code is valid for"  {...codeExpirationTime} autoComplete="off" tabIndex={1}/>
                        <Form.Text muted>
                            Your generated OTP codes will be valid for the number of seconds specified in this input.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicProvider">
                        <Form.Label>Provider {value}</Form.Label>
                        <Dropdown.Menu show>
                            <Dropdown.Header>Select your provider</Dropdown.Header>
                            <Dropdown.Item onClick={() => setValue("0")} eventKey="0">SMS OTP</Dropdown.Item>
                            <Dropdown.Item onClick={() => setValue("1")} eventKey="1">API OTP</Dropdown.Item>
                            <Dropdown.Item onClick={() => setValue("2")} eventKey="2">EMAIL OTP</Dropdown.Item>
                        </Dropdown.Menu>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicBrand">
                        <Form.Label>Brand name</Form.Label>
                        <Form.Control type="text" placeholder="Brand name"  {...brandName} autoComplete="off" tabIndex={1}/>
                        <Form.Text muted>
                            This name will be used in the OTP notifications.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicWebsite">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text" placeholder="Website"  {...website} autoComplete="off" tabIndex={1}/>
                        <Form.Text muted>
                            This name will be used in the OTP notifications.
                        </Form.Text>
                    </Form.Group>
                    <div className='text-center'>
                        <Button variant="primary" onClick={handleCreateApiKey} disabled={loading} tabIndex={3}>{loading ? 'Loading...' : 'Login'}</Button>
                    </div>
                </Col>

            </Row>
        </Container >
    );
};

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default CreateApiKey;