import React from 'react';
import {getUser, removeUserSession} from './Utils/Common';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

//icons
//https://react-icons.github.io/react-icons/icons?name=fa
import {
    FaShieldAlt,
    FaPaypal,
    FaPerbyte,
    FaFile,
    FaUser,
    FaRegSun
} from "react-icons/fa";
import {Dropdown, NavItem, NavLink, NavDropdown} from "react-bootstrap";
import {AiOutlineUser, VscAccount} from "react-icons/all";

function Navigation() {

    // handle click event of logout button
    const handleLogout = () => {
        removeUserSession();
        //TODO: i need to find a solution for this. it is not ok with windows.location.href.
        window.location.href = '/login'
    }

    let user = getUser();
    return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href="/"><FaShieldAlt></FaShieldAlt> My APP</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    {/*This totally empty navbar with the class 'me-auto' is significant. */}
                    <Nav className="me-auto" defaultActiveKey="/dashboard">
                        <Nav.Link href="/dashboard">
                            <FaPerbyte></FaPerbyte> Dashboard
                        </Nav.Link>
                        <Nav.Link href="/pay">
                            <FaPaypal></FaPaypal> Buy Credits
                        </Nav.Link>
                        <Nav.Link href="/orders">
                            <FaFile></FaFile> Orders
                        </Nav.Link>
                    </Nav>
                    {/*It is responsible for the other nav bar content moving to the right.*/}
                    <Nav className={"user-profile-icon"}>
                        <Dropdown as={NavItem}>
                            <Dropdown.Toggle as={NavLink}><FaUser className={"fa-5x"}></FaUser></Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                <Dropdown className={"text-center text-muted"}>
                                   <FaUser></FaUser> {user.email}
                                </Dropdown>
                                <NavDropdown.Divider/>
                                <Dropdown.Item>
                                    <AiOutlineUser></AiOutlineUser> Profile[x]
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <VscAccount></VscAccount> Account[x]
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <FaRegSun></FaRegSun> Settings[x]
                                </Dropdown.Item>
                                <NavDropdown.Divider/>
                                <Dropdown.Item onClick={handleLogout}>
                                    Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}

export default Navigation;
